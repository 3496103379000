import React from 'react'
import Layout from '../Layouts/index'
import Button from '../components/Button/Button'

import './404.scss'

const NotFoundPage = ({ location }) => (
  <Layout location={location}>
    <div className="page-not-found">
      <div className="page-not-found__wrapper">
      <h1 className="header-h2">Sorry! Page not found!</h1>
        <Button link="/" text="Back To Home" elementStyle="light-bg" />
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
